import React, { useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { IconButton } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import BarcodePDFDocument from "./BarcodePDFDocument";

const ExportBarcodeButton = ({ selectedBarcodes }) => {
  const [generatePDF, setGeneratePDF] = useState(false);

  return (
    <>
      <IconButton
        aria-label="export barcode pdf"
        disabled={selectedBarcodes.length === 0}
        sx={{ color: "white" }}
        onClick={() => setGeneratePDF(!generatePDF)}
      >
        <PictureAsPdfIcon fontSize="large" />
      </IconButton>
      {(generatePDF && selectedBarcodes.length) > 0 && (
        <IconButton disabled={selectedBarcodes.length === 0}>
          <PDFDownloadLink
            document={<BarcodePDFDocument barcodes={selectedBarcodes} />}
            fileName="barcodes.pdf"
            style={{
              textDecoration: "none",
              color: "white",
              marginLeft: 10,
              fontSize: 15,
              backgroundColor: "orange",
              borderRadius: 4,
            }}
          >
            Download PDF
          </PDFDownloadLink>
        </IconButton>
      )}
    </>
  );
};

export default ExportBarcodeButton;
