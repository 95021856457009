import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

// Define styles
const styles = StyleSheet.create({
  page: {
    width: "80mm",
    padding: "2mm",
  },
  barcodeLine: {
    marginBottom: 2, 
    display: "flex",
    flexDirection: "column", 
    alignItems: "center", 
  },
  barcodeImage: {
    width: "70mm", 
    height: "20mm", 
    objectFit: "contain", 
  },
});

// PDF Document Component
const BarcodePDFDocument = ({ barcodes }) => (
  <Document>
    <Page size={{ width: '80mm', height: "auto" }} style={styles.page}>
      {barcodes.map((barcode, index) => (
        <View key={index} style={styles.barcodeLine}>
          <Image src={barcode} style={styles.barcodeImage} />
        </View>
      ))}
    </Page>
  </Document>
);

export default BarcodePDFDocument;
