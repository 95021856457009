import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import ET from "../../../assets/images/ET-flag.png";
// import { useReminders } from "../APIs/EmployeeAPI";
import EmployeeProfileView from "../upload/EmployeeProfileView";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { useAuth } from "../../../Context/AuthContext";
import loginService from "../../../services/login.service";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import DailyQuote from "../Widgets/DailyQuote";
import { Offline, Online } from "react-detect-offline";
import SignalWifiConnectedNoInternet4Icon from "@mui/icons-material/SignalWifiConnectedNoInternet4";
import NoInternet from "../Statuses/NoInternet";
import StatusPill from "../ContributionGraph/StatusPill";
import Employeereportmessage from "../Messages/Employeereportmessage";
import SurveyChecker from "../../pages/Gen/Survey/SurveyChecker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SearchIcon from "@mui/icons-material/Search";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import useEmployeeOnlineStatus from "../Employee/useEmployeeOnlineStatus";
function Dashboard({ children }) {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [ProfileDropdown, setProfileDropdown] = useState(false);
  const { setIsLogged, employee, isAdmin } = useAuth();
  // const { reminders } = useReminders();
  const storedVisibility = localStorage.getItem("sidebarVisible");

  const NotAv = () => {
    alert(
      "Apologies, the service is currently unavailable. We're working hard to bring you an improved experience with version 2.1. Please check back soon!"
    );
  };
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { onlineEmployees: employees, status } = useEmployeeOnlineStatus();
  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement
        .requestFullscreen()
        .then(() => {
          setIsFullscreen(true);
        })
        .catch((err) => {
          console.log(
            `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
          );
        });
    } else {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullscreen(false);
        })
        .catch((err) => {
          console.log(
            `Error attempting to exit full-screen mode: ${err.message} (${err.name})`
          );
        });
    }
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === ",") {
        setSidebarVisible(true);
      } else if (event.ctrlKey && event.key === ".") {
        setSidebarVisible(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = days[currentDate.getDay()];
  const month = months[currentDate.getMonth()];
  const date = currentDate.getDate();

  const formattedDate = `${day} ${month} ${date}`;

  const handleDropdownprofile = () => {
    setProfileDropdown(!ProfileDropdown);
  };

  const toggleSidebar = () => {
    const newVisibility = !isSidebarVisible;
    setSidebarVisible(newVisibility);
    localStorage.setItem("sidebarVisible", JSON.stringify(newVisibility));
  };
  const goBack = () => {
    window.history.back();
  };
  const goForward = () => {
    window.history.forward();
  };
  const logOut = () => {
    // Display a confirmation dialog
    const confirmLogout = window.confirm(
      "Are you sure you want to log out? You may need to enter the OTP code again to log in."
    );

    // Check the user's response
    if (confirmLogout) {
      // Call the logout function from the login service
      loginService.logOut();
      // Set the isLogged state to false
      setIsLogged(false);
      window.location.href = "/";
    }
  };
  // const EmployeeID = employee?.employee_id;
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  return (
    <div className="container-fluid position-relative d-flex p-0">
      {/* Content Start */}
      <div
        className={`content content__ ${
          !isSidebarVisible || storedVisibility !== "true"
            ? "open_ content_ "
            : ""
        }`}
      >
        {/* Navbar Start */}
        <nav className="navbar bg-custom-d-  navbar-expand navbar-dark sticky-top px-4 py-0">
          {/* <Link to="/" className="navbar-brand d-flex d-lg-none me-4">
            <h2 className="bg-custom-d-  mb-0">
              <i className="fa fa-user-edit">Dashboard</i>
            </h2>
          </Link> */}
          <div onClick={goBack} className="text-white dsmnone">
            <div className="">
              <ArrowLeftIcon className="w-100" />
            </div>
          </div>
          <Link
            onClick={toggleSidebar}
            className="sidebar-toggler flex-shrink-0"
          >
            {isSidebarVisible ? (
              <MenuIcon className="menu-icon" />
            ) : (
              <MenuOpenIcon className="menu-icon" />
            )}
          </Link>
          <div onClick={goForward} className="text-white me-2 dsmnone">
            <div className="">
              <ArrowRightIcon className="w-100" />
            </div>
          </div>
          <Link
            className="nav-link align-items-center dsmnone"
            onClick={toggleFullscreen}
          >
            {isFullscreen ? (
              <FullscreenExitIcon className="materialuiicon text-white fs-2" />
            ) : (
              <FullscreenIcon className="materialuiicon text-white fs-2" />
            )}
          </Link>
          <div className="d-none d-md-flex ms-3 text-white">
            <TextField
              id="DashboardSearchinput"
              placeholder="Search"
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "white" }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#1F252B",
                color: "white",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#404040",
                  },
                  "&:hover fieldset": {
                    borderColor: "orange",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "orange",
                  },
                },
                input: {
                  color: "white",
                },
              }}
            />
          </div>
          <StatusPill />
          <div className="navbar-nav align-items-center  ms-auto ">
            <DailyQuote className="text-gray" />

            {/* <div className="nav-item dropdown ">
              <button className="nav-link align-items-center">
                <img src={ET} width="25" />
              </button>
            </div>{" "} */}
            <div className="nav-item dropdown ">
              <button onClick={NotAv} className="nav-link align-items-center">
                <MailOutlinedIcon className="msg-notif-icon  rounded-circle" />

                <span className="d-none d-lg-inline-flex m-1 text-light-gray">
                  Message
                </span>
              </button>
            </div>
            <div className="nav-item dropdown align-items-center">
              <button onClick={NotAv} className="nav-link ">
                <NotificationsNoneOutlinedIcon className="msg-notif-icon rounded-circle" />

                <span className="d-none d-lg-inline-flex m-1 text-light-gray">
                  Notification
                </span>
              </button>
            </div>
            <div className="nav-item dropdown "></div>
            <div className="nav-item dropdown">
              <Link
                className="nav-link d-flex align-items-center"
                onClick={handleDropdownprofile}
              >
                <EmployeeProfileView
                  nav={true}
                  picstyle={{
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                  }}
                  w={50}
                />

                {/* <AccountCircleIcon className="matrialuiicon- text-warning rounded-circle " /> */}
                <span className="d-none d-lg-inline-flex m-1 ms-2">
                  {employee?.employee_first_name} {employee?.employee_last_name}
                </span>
              </Link>
              <div
                className={`dropdown-menu ${
                  ProfileDropdown ? "show" : ""
                } bg-custom-d- border-0 `}
              >
                <Link
                  to="/dashboard/employee/personal/account"
                  className="dropdown-item"
                >
                  My Profile
                </Link>
                <Link onClick={NotAv} className="dropdown-item">
                  Settings
                </Link>
                <Link onClick={logOut} className="dropdown-item">
                  Log Out
                </Link>
              </div>
            </div>
          </div>
        </nav>
        {/* Navbar End */}
        {employee?.employee_role !== 3 && <Employeereportmessage />}

        <SurveyChecker />
        <div className="container-fluid pt-4 px-4">
          <div className="row bg-custom-d- mobile-v-stl rounded dashboard-main justify-content-center mx-0">
            <div className="col-md-12 text-center">
              {/* <Online> {children}</Online> */}
              {/* <Offline>
                <NoInternet />
              </Offline> */}
              {/* Tempo */}
              {children}
            </div>
          </div>
        </div>
        {/* Footer Start */}
        <div className="container-fluid pt-4 px-4 border-light  ">
          <div className="bg-custom-d- rounded-top p-4 text-secondary ">
            <div className="row d-sm-block">
              <div className="d-md-flex  d-sm-block align-items-end justify-content-between ">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: { xs: "column", sm: "row" },
                    textAlign: { xs: "center", sm: "start" },
                    padding: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ color: "white", fontSize: 10 }}
                  >
                    &copy;{" "}
                    <Link href="/" className="text-info" color="white">
                      Buee Agricultural One Stop Shop Center
                    </Link>
                    , All Rights Reserved.
                  </Typography>
                </Box>
                <Card
                  sx={{
                    maxWidth: 450,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: "#1F252B",
                    padding: 2,
                    color: "white",
                    mb: 3,
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{ marginBottom: 2, fontWeight: "bold", fontSize: 18 }}
                    >
                      Online Employees
                    </Typography>
                    <Box
                      sx={{
                        maxHeight: 250,
                        overflowY: "auto",
                        overflowX: "hidden",
                        marginBottom: 2,
                      }}
                    >
                      {employees && employees.length > 0 ? (
                        employees.map((employee) => (
                          <Box
                            key={employee.employee_id}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: 2,
                              padding: 0.9,
                              width: "fit-content",
                              bgcolor: "#252c33",
                              borderRadius: 30,
                            }}
                          >
                            {employee.employee_profile !== null ||
                            employee.employee_profile ? (
                              <Tooltip
                                title={`${employee.employee_first_name} From ${employee.employee_branch} Branch is Online`}
                                placement="left-start"
                              >
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  sx={{
                                    marginRight: 1.3,
                                  }}
                                >
                                  <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                    variant="dot"
                                  >
                                    <Avatar
                                      sx={{
                                        width: 40,
                                        height: 40,
                                      }}
                                      alt="Profile pic"
                                      src={`data:image/webp;base64,${employee.employee_profile}`}
                                    />
                                  </StyledBadge>
                                </Stack>
                              </Tooltip>
                            ) : (
                              <Avatar
                                sx={{
                                  bgcolor: "blueviolet",
                                  width: 40,
                                  height: 40,
                                  marginRight: 1.3,
                                }}
                              >
                                {employee.employee_first_name
                                  .charAt(0)
                                  .toUpperCase()}
                              </Avatar>
                            )}

                            <Box>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: "bold" }}
                              >
                                {employee.employee_first_name}{" "}
                                {employee.employee_last_name}
                              </Typography>
                              <Typography variant="body2">
                                {employee.employee_email}
                              </Typography>
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <Typography variant="body2" color="text.secondary">
                          No employees are online.
                        </Typography>
                      )}
                    </Box>
                  </CardContent>
                </Card>
                <div className="text-white">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Card
                      sx={{
                        maxWidth: 400,
                        borderRadius: "16px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        overflow: "hidden",
                        backgroundColor: "#1F252B",
                      }}
                    >
                      <CardContent>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            textAlign: "center",
                            color: "white",
                            fontWeight: "bold",
                            marginBottom: 2,
                          }}
                        >
                          Calendar
                        </Typography>
                        <DateCalendar
                          sx={{
                            "& .MuiPickersDay-root": {
                              color: "white",
                            },
                            "& .Mui-selected": {
                              backgroundColor: "rgba(255, 255, 255, 0.3)",
                              color: "white",
                            },
                            "& .MuiPickersDay-root:hover": {
                              backgroundColor: "rgba(255, 255, 255, 0.1)",
                            },
                            "& .MuiDayPicker-weekday": {
                              color: "orange",
                              fontWeight: "bold",
                            },
                            "& .MuiPickersDay-weekend": {
                              color: "orange",
                            },
                          }}
                        />
                      </CardContent>
                    </Card>
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer End */}
      </div>
      {/* Content End */}

      {/* Back to Top */}
      <Link className="btn back-to-top">
        <ArrowCircleUpIcon className="upicon" />
      </Link>
    </div>
  );
}

export default Dashboard;
