import React, { useState } from "react";
import Dashboard from "../../components/Dashboard/dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import useEmployeeOnlineStatus from "../../components/Employee/useEmployeeOnlineStatus";

function OnlineEmployees() {
  const { onlineEmployees: employees, status } = useEmployeeOnlineStatus();
  const [searchInput, setSearchInput] = useState("");
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredEmployees = employees
    ? employees.filter((employee) => {
        const fieldsToSearch = [
          "employee_first_name",
          "employee_last_name",
          "employee_email",
          "employee_phone",
          "status",
          "employee_branch",
          "field_of_study",
          "employee_privilege",
          "company_role_name",
          "employee_salary",
        ];

        return fieldsToSearch.some((field) =>
          String(employee[field])
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      })
    : [];
  return (
    <Dashboard>
      <>
        <div className="table-responsive employees-tab mb-5 mt-3">
          <div className="d-flex align-items-center mb-3">
            <div className=" mb-3 text-white">
              <input
                type="search"
                className="form-control w-100 bg-white"
                placeholder="Search online employees"
                value={searchInput}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
        <div class="container">
          <div class="main-body">
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 gutters-sm">
              {filteredEmployees.map((employee) => {
                return (
                  <div class="col mb-3" key={employee.employee_id}>
                    <div class="card bg-custom-d- border online-employee-card">
                      <div class="card-body text-center">
                        {employee.employee_profile !== null ||
                        employee.employee_profile ? (
                          <img
                            src={`data:image/webp;base64,${employee.employee_profile}`}
                            alt={`${employee.employee_profile} Online Employee Profile`}
                            style={{
                              marginTop: -65,
                              alignItems: "center",
                              maxWidth: "100px",
                              maxHeight: "100px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <div className="position-relative">
                            <AccountCircleIcon
                              style={{
                                marginTop: -65,
                                alignItems: "center",
                              }}
                              className="online_employee-no-pic-icon text-warning rounded-circle "
                            />
                          </div>
                        )}
                        <h5 class="card-title text-white">
                          {" "}
                          {employee.employee_first_name}{" "}
                          {employee.employee_last_name}
                        </h5>
                        <p class="text-secondary mb-1">
                          {" "}
                          {employee.employee_email}
                        </p>{" "}
                        <p class="text-secondary mb-1">
                          {" "}
                          {employee.company_role_name}
                        </p>
                        <p class="text-secondary mb-1">
                          Working Branch {employee.employee_branch} AOSS Center
                        </p>
                      </div>
                      <div class="card-footer">
                        <span
                          className={`badge rounded-pill  ${
                            employee.status === "online"
                              ? "bg-success"
                              : "bg-primary"
                          }  `}
                        >
                          {employee.status}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    </Dashboard>
  );
}

export default OnlineEmployees;
