import { useState, useEffect } from "react";
import socket from "../../../util/socket";
import employeeStatusService from "../../../services/employee_status.service";
const useEmployeeOnlineStatus = () => {
  const [onlineEmployees, setOnlineEmployees] = useState([]);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const employeeId = localStorage.getItem("EmployeeID");

    if (employeeId) {
      socket.emit("employeeOnline", employeeId);

      const handleVisibilityChange = () => {
        if (document.hidden) {
          socket.emit("employeeOffline", employeeId);
        } else {
          socket.emit("employeeOnline", employeeId);
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        socket.emit("employeeOffline", employeeId);
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }
  }, []);

  useEffect(() => {
    const employeeId = localStorage.getItem("EmployeeID");

    const fetchInitialStatus = async () => {
      try {
        const response = await employeeStatusService.getEmployeeStatus(
          employeeId
        );
        setStatus(response.data);
      } catch (error) {
        console.error("Error fetching employee status:", error);
      }
    };

    fetchInitialStatus();

    socket.on("statusUpdate", (updatedStatus) => {
      if (updatedStatus.employee_id === employeeId) {
        setStatus(updatedStatus);
      }
    });

    return () => {
      socket.off("statusUpdate");
    };
  }, []);

  useEffect(() => {
    const handleOnlineEmployeesUpdate = (updatedOnlineEmployees) => {
      setOnlineEmployees(updatedOnlineEmployees);
    };

    socket.on("onlineEmployeesUpdate", handleOnlineEmployeesUpdate);

    return () => {
      socket.off("onlineEmployeesUpdate", handleOnlineEmployeesUpdate);
    };
  }, []);

  return { onlineEmployees, status };
};

export default useEmployeeOnlineStatus;
