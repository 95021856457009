import React, { useState } from "react";
import Dashboard from "../Dashboard/dashboard";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import PieChartIcon from "@mui/icons-material/PieChart";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import ReplyIcon from "@mui/icons-material/Reply";
import EditSoldProduct from "./EditSoldProduct";
import { formatDistanceToNow } from "date-fns";
import { parseISO } from "date-fns";
import { format } from "date-fns";
import { Link } from "react-router-dom";

import {
  calculateCurrentMonthRevenue,
  calculateTodayRevenue,
  calculateTotalProductQuantity,
  calculateTotalRevenue,
} from "../Functions/CalculateFunctions";
import AgroSoldProductTypePieChart from "../Charts/PieCharts/ProductTypePieChart";
import AgroWeeklySalesLineChart from "../Charts/SingleLineCharts/WeeklySalesLineChart";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditNoteIcon from "@mui/icons-material/EditNote";
import FilterListCard from "../Products/FilterListCard";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  TableFooter,
  TablePagination,
  Chip,
  Button,
} from "@mui/material";

function SoldProducts({
  handleDeleteProduct,
  products,
  soldProducts,
  isLoading,
  successMessage,
  HandleEdit,
  service,
  branchs,
  // scanSuccess,
}) {
  const [showAll, setShowAll] = useState(false);
  const [Error, setError] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [productTypeFilter, setProductTypeFilter] = useState("");
  const [productUnitFilter, setProductUnitFilter] = useState("");
  const [branchFilter, setBranchFilter] = useState("");
  const [showFilterCard, setShowFilterCard] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDates, setShowDates] = useState(false);
  const [showFullNames, setshowFullNames] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });
  const handleShowFullNames = () => {
    setshowFullNames(!showFullNames);
  };
  const handleShowDates = () => {
    setShowDates(!showDates);
  };
  const handleToggleFilterCard = () => {
    setShowFilterCard(!showFilterCard);
  };
  const [editingProduct, setEditingProduct] = useState(null);

  const handleEdit = (soldProducts) => {
    setEditingProduct(soldProducts);
  };
  const handleCancelEdit = () => {
    setEditingProduct(null);
  };
  const handleFilterByProductType = (selectedOption) => {
    // Check if selectedOption is not null before accessing its value
    if (selectedOption && selectedOption.value) {
      setProductTypeFilter(selectedOption.value);
    } else {
      // If selectedOption is null or doesn't have a value, clear the filter
      setProductTypeFilter("");
    }
  };

  const handleFilterByProductUnit = (selectedOption) => {
    // Check if selectedOption is not null before accessing its value
    if (selectedOption && selectedOption.value) {
      setProductUnitFilter(selectedOption.value);
    } else {
      // If selectedOption is null or doesn't have a value, clear the filter
      setProductUnitFilter("");
    }
  };

  const handleFilterByBranch = (e) => {
    setBranchFilter(e.target.value);
  };

  const handleSetStartDate = (date) => {
    setStartDate(date);
  };

  const handleSetEndDate = (date) => {
    setEndDate(date);
  };
  const handleSetAdjustmentId = (ProductId) => {
    localStorage.setItem("adjustment", ProductId);
  };
  const filteredProducts = showAll
    ? soldProducts
    : soldProducts
        .filter((soldProducts) => {
          const fieldsToSearch = [
            "product_id",
            "product_name",
            "product_unit_price",
          ];
          const soldDate = new Date(soldProducts.sold_date);
          return (
            (!startDate || soldDate >= startDate) &&
            (!endDate || soldDate <= endDate) &&
            fieldsToSearch.some((field) =>
              String(soldProducts[field])
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            ) &&
            (productTypeFilter === "" ||
              soldProducts.product_type.toLowerCase() ===
                productTypeFilter.toLowerCase()) &&
            (productUnitFilter === "" ||
              soldProducts.product_unit.toLowerCase() ===
                productUnitFilter.toLowerCase()) &&
            (branchFilter === "" ||
              soldProducts.branch.toLowerCase() === branchFilter.toLowerCase())
          );
        })
        .slice(0, rowsPerPage);
  const clearFilters = () => {
    setBranchFilter("");
    setProductUnitFilter("");
    setProductTypeFilter("");
    setStartDate(null);
    setEndDate(null);
    setshowFullNames(false);
    setShowDates(false);
  };
  // Calculate today's revenue for the filtered products
  const todayRevenue = calculateTodayRevenue(
    filteredProducts,
    "sold_date",
    "product_total_price"
  );

  // Calculate total revenue for the filtered products
  const totalRevenue = calculateTotalRevenue(
    filteredProducts,
    "product_total_price"
  );

  // Calculate current month's revenue for the filtered products
  const currentMonthRevenue = calculateCurrentMonthRevenue(
    filteredProducts,
    "sold_date",
    "product_total_price"
  );

  // Calculate total quantity for the filtered products
  const totalProductQuantity = calculateTotalProductQuantity(
    filteredProducts,
    "product_quantity"
  );
  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (!sortConfig.key) return 0;
    if (a[sortConfig.key] < b[sortConfig.key])
      return sortConfig.direction === "asc" ? -1 : 1;
    if (a[sortConfig.key] > b[sortConfig.key])
      return sortConfig.direction === "asc" ? 1 : -1;
    return 0;
  });

  // Paginated data
  const paginatedProducts = sortedProducts.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };
  return (
    <Dashboard>
      {editingProduct ? (
        <EditSoldProduct
          ProductId={editingProduct.product_id}
          onCancel={handleCancelEdit}
          editingProduct={editingProduct}
          products={products}
          handleEdit={HandleEdit}
          service={service}
          branchs={branchs}
        />
      ) : (
        <>
          <div className="container-fluid pt-4 px-4 text-white">
            {Error && <div className="alert alert-danger">{Error}</div>}
            {successMessage && (
              <div className="alert alert-success">{successMessage}</div>
            )}

            <div className="row g-4">
              <div className="col-sm-6 col-xl-3">
                <div className="ovr-3 rounded d-flex align-items-center justify-content-between p-4">
                  <i>
                    <StackedLineChartIcon className="newsalesicon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Today Revenue</p>
                    <h6 className="mb-0">ETB {todayRevenue.toFixed(2)}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="ovr-10 rounded d-flex align-items-center justify-content-between p-4">
                  <i>
                    <MonetizationOnIcon className="newsalesicon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Month's Revenue</p>
                    <h6 className="mb-0">
                      ETB {currentMonthRevenue.toFixed(2)}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="ovr-4 rounded d-flex align-items-center justify-content-between p-4">
                  <i>
                    <LeaderboardIcon className="newsalesicon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Total Qty</p>
                    <h6 className="mb-0">QTY {totalProductQuantity}</h6>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3">
                <div className="ovr-5 rounded d-flex align-items-center justify-content-between p-4">
                  <i>
                    <PieChartIcon className="newsalesicon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Total Revenue</p>
                    <h6 className="mb-0">ETB {totalRevenue.toFixed(2)}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid  pt-4 px-4">
            <div className="row g-4">
              <div className="col-sm-12 col-xl-6">
                <div className="bg-black text-center rounded p-4">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h6 className="mb-0 text-info ">Weekly Sales</h6>
                    <Link className="text-danger">Show All</Link>
                  </div>
                  <AgroWeeklySalesLineChart soldProducts={filteredProducts} />
                </div>
              </div>
              <div className="col-sm-12 col-xl-6">
                <div className="bgsecondary text-center rounded p-2">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h6 className="mb-0 text-success">Product Type Sales</h6>
                    <Link className="text-danger">Show All</Link>
                  </div>
                  <AgroSoldProductTypePieChart products={filteredProducts} />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid pt-4 px-4 mb-5">
            <div className={`${"clonestorev text-center rounded p-4"}`}>
              <div className="d-lg-flex d-md-flex  d-sm-inline-block d-sm-block   align-items-center justify-content-between mb-4">
                <div className="form-floating  ">
                  <input
                    type="search"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Search Products"
                    value={searchInput}
                    onChange={handleSearch}
                  />
                  <label htmlFor="floatingInput">Search Products</label>
                </div>

                <FilterListCard
                  showFilterCard={showFilterCard}
                  handleToggleFilterCard={handleToggleFilterCard}
                  handleFilterByBranch={handleFilterByBranch}
                  handleFilterByProductUnit={handleFilterByProductUnit}
                  handleFilterByProductType={handleFilterByProductType}
                  branchFilter={branchFilter}
                  productUnitFilter={productUnitFilter}
                  productTypeFilter={productTypeFilter}
                  handleSetStartDate={handleSetStartDate}
                  handleSetEndDate={handleSetEndDate}
                  service={service}
                />

                {/* <Link className="xpm" onClick={handleShowAll}>
                  {showAll ? "Show Less" : "Show All"}
                </Link> */}
                <Chip
                  label={showAll ? "Show Less" : "Show All"}
                  variant="outlined"
                  sx={{ color: "white" }}
                  onClick={handleShowAll}
                />
              </div>
              {/* {scanSuccess.map((message, index) => (
                <div key={index} class="alert alert-success" role="alert">
                  {message}
                </div>
              ))} */}
              <div className="table-responsive">
                <div className="d-flex mt-0 ">
                  <h6 className="text-white text-start mb-2 me-2">
                    Recent Salse
                  </h6>
                  {/* <Link className="xpm me-2 btn btn-sm btn-outline-warning "></Link> */}

                  <Button
                    onClick={handleShowDates}
                    variant="contained"
                    size="small"
                    sx={{ m: 0.2, color: "white" }}
                  >
                    {showDates ? "Hide dates" : "Show dates"}
                  </Button>
                  {/* <button className="btn btn-outline-info  btn-sm me-2"></button> */}
                  <Button
                    onClick={handleToggleFilterCard}
                    variant="contained"
                    size="small"
                    color="success"
                    sx={{ m: 0.2, color: "white" }}
                  >
                    Filters
                  </Button>
                  {/* <button className="btn-ccgray text-white btn-sm me-2"></button> */}
                  <Button
                    onClick={handleShowFullNames}
                    variant="outlined"
                    size="small"
                    color="secondary"
                    sx={{ m: 0.2, color: "white" }}
                  >
                    {showFullNames ? "Hide Full Names" : "Show Full Names"}
                  </Button>

                  <Button
                    onClick={clearFilters}
                    variant="contained"
                    color="error"
                    size="small"
                    sx={{ m: 0.2, color: "white" }}
                  >
                    Clear Filters
                  </Button>
                  {/* <button className="btn btn-outline-danger  btn-sm me-2"></button> */}
                </div>
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "none",
                    mt: 4,

                    // Remove border/shadow of the table container
                  }}
                >
                  <Table
                    sx={{
                      minWidth: 650,
                      borderCollapse: "collapse",
                      backgroundColor: "#131920",
                      "& .MuiTableCell-root": {
                        borderBottom: "none",
                        borderRadius: "none",
                        color: "#fff",
                        padding: "12px",
                      },
                    }}
                    aria-label="sold products table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Product ID</TableCell>
                        <TableCell>Product Name</TableCell>
                        <TableCell>Product Type</TableCell>
                        <TableCell>Branch</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Unit Price</TableCell>
                        <TableCell>Total Price</TableCell>
                        <TableCell>Sold Date</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {paginatedProducts.map((product, index) => (
                        <TableRow
                          key={product.product_id}
                          sx={{
                            backgroundColor:
                              index % 2 === 0 ? "#131920" : "#252A31",
                            "&:hover": {
                              backgroundColor: "#252A31",
                              color: "#000",
                            },
                          }}
                        >
                          <TableCell>{product.product_id}</TableCell>
                          <TableCell
                            sx={{
                              whiteSpace: showFullNames ? "normal" : "nowrap",
                              overflow: showFullNames ? "visible" : "hidden",
                              textOverflow: showFullNames ? "clip" : "ellipsis",
                              maxWidth: showFullNames ? "none" : 130,
                            }}
                          >
                            {product.product_name}
                          </TableCell>

                          <TableCell
                            sx={{
                              whiteSpace: showFullNames ? "normal" : "nowrap",
                              overflow: showFullNames ? "visible" : "hidden",
                              textOverflow: showFullNames ? "clip" : "ellipsis",
                              maxWidth: showFullNames ? "none" : 100,
                            }}
                          >
                            {product.product_type}
                          </TableCell>
                          <TableCell>{product.branch}</TableCell>
                          <TableCell>{product.product_quantity}</TableCell>
                          <TableCell>{product.product_unit_price}</TableCell>
                          <TableCell>{product.product_total_price}</TableCell>

                          {showDates ? (
                            <TableCell>
                              <Chip
                                label={format(
                                  parseISO(product.sold_date),
                                  "MM/dd/yyyy HH:mm:ss"
                                )}
                                sx={{ bg: "info", color: "white" }}
                                variant="outlined"
                              />
                            </TableCell>
                          ) : (
                            <TableCell>
                              <Chip
                                label={formatDistanceToNow(
                                  parseISO(product.sold_date),
                                  {
                                    addSuffix: true,
                                  }
                                )}
                                sx={{
                                  backgroundColor: "black",
                                  color: "white",
                                }}
                              />
                            </TableCell>
                          )}
                          <TableCell>
                            <Tooltip title="Edit">
                              <IconButton
                                sx={{ color: "white" }}
                                onClick={() => handleEdit(product)}
                              >
                                <EditNoteIcon />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Delete">
                              <IconButton
                                color="error"
                                onClick={() => {
                                  handleSetAdjustmentId(product.product_id);
                                  handleDeleteProduct(product.product_id);
                                }}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={"Replay Product"}>
                              <Link to="/dashboard/help-request">
                                <IconButton
                                  aria-label="delete"
                                  sx={{ color: "red" }}
                                  onClick={() =>
                                    handleSetAdjustmentId(product.product_id)
                                  }
                                >
                                  <ReplyIcon className="Sicon" />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[
                            5, 10, 50, 100, 250, 500, 700, 1000, 5000, 10000,
                            20000,
                          ]}
                          count={filteredProducts.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </>
      )}
    </Dashboard>
  );
}

export default SoldProducts;
