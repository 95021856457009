import { Avatar, Badge, styled } from "@mui/material";
import React from "react";

function PictureViewer({ style, pic }) {
  if (!pic) return null;

  const byteArray = new Uint8Array(pic.data);
  const byteString = String.fromCharCode.apply(null, byteArray);
  const base64String = btoa(byteString);
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));
  return (
    <StyledBadge
      overlap="circular"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      variant="dot"
    >
      <Avatar
        sx={{
          ...style,
        }}
        alt="Profile pic"
        src={`data:image/jpeg/png;base64,${base64String}`}
        x
      />
    </StyledBadge>
    // <img
    //   src={`data:image/jpeg/png;base64,${base64String}`}
    //   alt="Employee"
    //   style={{
    //     ...style,
    //   }}
    // />
  );
}

export default PictureViewer;
